<template>
  <div class="my-pushu-detail">
    <navbar fixed>
      <template #right>
        <span class="link" @click="$router.push(`${detail.id}/preview`)"
          >预览谱书</span
        >
      </template>
    </navbar>
    <div class="detail-content">
      <div v-for="item in detailForm" :key="item.key" class="item">
        <div class="label">{{ item.label }}</div>
        <div class="value">{{ detail[item.key] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import materialApis from "@/apis/material";
import Navbar from "@/components/h5/Navbar";

// const mockData = new Array(10).fill({}).map((item, index) => ({
//   ...item,
//   id: index + 1,
//   timing: "题目",
//   tanghao: "堂号",
//   fayuandi: "发源地",
//   zhuanxieriqi: "编撰日期",
//   price: [0, 1][index % 2],
//   cover: require("@/assets/images/search/cover.png")
// }));

const detailForm = [
  {
    label: "编号",
    key: "sn"
  },
  {
    label: "题目",
    key: "title"
  },
  {
    label: "价格",
    key: "price"
  },
  {
    label: "描述",
    key: "description"
  },
  {
    label: "卷数",
    key: "juanshu"
  },
  {
    label: "谱籍地",
    key: "pujidi"
  },
  {
    label: "始祖",
    key: "shizu"
  },
  {
    label: "始迁祖",
    key: "shiqianzu"
  },
  {
    label: "支系",
    key: "timing"
  },
  {
    label: "修撰者",
    key: "xiuzhuanzhe"
  },
  {
    label: "版本年",
    key: "banbennian"
  },
  {
    label: "堂号",
    key: "tanghao"
  },
  {
    label: "字辈",
    key: "zibei"
  },
  {
    label: "编撰日期",
    key: "zhuanxieriqi"
  },
  {
    label: "发源地",
    key: "fayuandi"
  }
];

export default {
  name: "MyPushuDetail",
  components: {
    Navbar
  },
  data() {
    return {
      detailForm,
      detail: {}
    };
  },
  created() {
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      const id = this.$route.params.id;
      // const id = 2;
      if (!id) return;
      const {
        data: { material_info }
      } = await materialApis.myMaterialDetail({
        id
      });
      this.detail = material_info;
      console.log(material_info);
    }
  }
};
</script>

<style lang="scss" scoped>
.my-pushu-detail {
  width: 1125px;
  min-height: 100vh;
  background-color: #ffffff;

  .link {
    color: #148bfa;
  }
}

.detail-content {
  padding: 0 40px 40px 40px;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 42px 0;
    border-bottom: 1px solid #f0f0f0;

    .label {
      font-size: 44px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }

    .value {
      font-size: 44px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
